<template>
  <modal-default refs="modal-no-scheme" :buttonsSlot="false" classes="dialog-max-width-1000">
    <template v-slot:content>
      <h1 class="h3">
        {{ $translations.getTranslation('general_scheme_terms_modal_title') }}
      </h1>

      <ol>
        <!-- 1. -->
        <li>
          <strong>{{ $translations.getTranslation('general_scheme_terms_modal_point_1_title') }}</strong>

          <ol>
            <li>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_1_1_text') }}
            </li>
            <li>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_1_2_text1_part1') }}
              <a href="https://fdm.dk/om-fdm/fdms-forretningsbetingelser" rel="noopener" target="_blank"
                 aria-describedby="accessibility-external-link-disclaimer">

                {{ $translations.getTranslation('general_scheme_terms_modal_point_1_2_link') }}
                <b-icon icon="box-arrow-up-right" aria-hidden="true" role="presentation"></b-icon>
              </a>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_1_2_text1_part2') }}
            </li>
          </ol>
        </li>

        <!-- 2. -->
        <li>
          <strong>{{ $translations.getTranslation('general_scheme_terms_modal_point_2_title') }}</strong>

          <ol>
            <li>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_2_1_text') }}
            </li>
            <li>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_2_2_text') }}
            </li>
            <li>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_2_3_text') }}
            </li>
            <li>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_2_4_text') }}
            </li>
            <li>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_2_5_text1_part1') }}
              <a href="https://fdm.dk/rabatter" rel="noopener" target="_blank"
                 aria-describedby="accessibility-external-link-disclaimer">

                {{ $translations.getTranslation('general_scheme_terms_modal_point_2_5_link1') }}
                <b-icon icon="box-arrow-up-right" aria-hidden="true" role="presentation"></b-icon>
              </a>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_2_5_text1_part2') }}
              <a href="https://fdm.dk/rabatter" rel="noopener" target="_blank"
                 aria-describedby="accessibility-external-link-disclaimer">

                {{ $translations.getTranslation('general_scheme_terms_modal_point_2_5_link2') }}
                <b-icon icon="box-arrow-up-right" aria-hidden="true" role="presentation"></b-icon>
              </a>
            </li>
            <li>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_2_6_text') }}
            </li>
            <li>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_2_7_text') }}
            </li>
          </ol>
        </li>

        <!-- 3. -->
        <li>
          <strong>{{ $translations.getTranslation('general_scheme_terms_modal_point_3_title') }}</strong>

          <ol>
            <li>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_3_1_txt_part_1') }}
              <a href="https://fdm.dk/om-fdm/persondatapolitik" rel="noopener" target="_blank"
                 aria-describedby="accessibility-external-link-disclaimer">

                {{ $translations.getTranslation('general_scheme_terms_modal_point_3_1_txt_link1') }}
                <b-icon icon="box-arrow-up-right" aria-hidden="true" role="presentation"></b-icon>
              </a>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_3_1_txt_part_2') }}
              <a href="mailto:kontaktfdm@fdm.dk">
                {{ $translations.getTranslation('general_scheme_terms_modal_point_3_1_txt_link2') }}
              </a>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_3_1_txt_part_3') }}
            </li>
            <li>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_3_2_text') }}
            </li>
            <li>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_3_3_text') }}
            </li>
            <li>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_3_4_text') }}
            </li>
            <li>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_3_5_text_part1') }}

              <a :href="getRoutePath('ManageCards')" @click.prevent="goToRoute('ManageCards')">
                {{ $translations.getTranslation('general_scheme_terms_modal_point_3_5_link_1') }}
              </a>
            </li>
            <li>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_3_6_text') }}
            </li>
            <li>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_3_7_text1_part1') }}
              <a href="https://mit.fdm.dk/" rel="noopener" target="_blank"
                 aria-describedby="accessibility-external-link-disclaimer">

                {{ $translations.getTranslation('general_scheme_terms_modal_point_3_7_link') }}
                <b-icon icon="box-arrow-up-right" aria-hidden="true" role="presentation"></b-icon>
              </a>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_3_7_text1_part2') }}
            </li>
            <li>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_3_8_text') }}
            </li>
            <li>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_3_12_text_part1') }}
              <a href="https://fdm.dk/om-fdm/persondatapolitik" rel="noopener" target="_blank"
                 aria-describedby="accessibility-external-link-disclaimer">

                {{ $translations.getTranslation('general_scheme_terms_modal_point_3_12_link') }}
                <b-icon icon="box-arrow-up-right" aria-hidden="true" role="presentation"></b-icon>
              </a>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_3_12_text_part2') }}
            </li>
          </ol>
        </li>

        <!-- 4. -->
        <li>
          <strong>{{ $translations.getTranslation('general_scheme_terms_modal_point_4_title') }}</strong>

          <ol>
            <li>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_4_1_text') }}
            </li>
            <li>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_4_2_text') }}
            </li>
          </ol>
        </li>

        <!-- 5. -->
        <li>
          <strong>{{ $translations.getTranslation('general_scheme_terms_modal_point_5_title') }}</strong>

          <ol>
            <li>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_5_1_text') }}
            </li>
            <li>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_5_2_text') }}
            </li>
            <li>
              {{ $translations.getTranslation('general_scheme_terms_modal_point_5_3_text') }}
            </li>
          </ol>
        </li>
      </ol>
    </template>
  </modal-default>
</template>

<script>
  import ModalDefault from '@/components/ui/modal/components/Default';

  // Mixins
  import { mixinRoutesHandler } from '@/mixins/routesHandler';

  export default {
    name: "ModalNoScheme",

    mixins: [mixinRoutesHandler],

    components: {
      ModalDefault
    },

    methods: {
      goToRoute(routeName) {
        this._mixinGoToRouteName(routeName);
      },

      getRoutePath(routeName) {
        return this._mixinGetRoutePath(routeName);
      },
    }
  }
</script>

<style lang="scss" scoped>
  ol {
    counter-reset: item
  }

  li {
    display: block;
    margin: 15px 0;
  }

  li:before {
    content: counters(item, ". ") ". ";
    counter-increment: item;
  }

  ol {
    padding-left: 0;
    list-style: decimal;
    margin-bottom: 30px;

    ol {
      padding-left: 60px;

      li:before {
        margin-left: -45px;
        position: absolute;
        left: 70px;
      }
    }
  }

  a {
    svg {
      font-size: 15px;
      vertical-align: -0.05em !important;
    }
  }
</style>
