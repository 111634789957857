<template>
  <default 
    :classes="classes" 
    :aria-disabled="ariaDisabled" 
    :disabled="disabled" 
    :spinner="spinner" 
    @click-state="$emit('click-state')"
  >
    <slot/>
  </default>
</template>

<script>
  import Default from '@/components/ui/button/components/Default';

  export default {
    name: "ButtonPrimary",

    props: {
      disabled: {
        default: false,
        type: Boolean
      },
      ariaDisabled: {
        default: false,
        type: Boolean
      },
      spinner: {
        default: false,
        type: Boolean
      }
    },

    components: {
      Default,
    },

    data: function () {
      return {
        classes: 'button-secondary',
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
