<template>
  <div id="app">
    <link-skip-main-content/>
    <alert-global/>

    <app-header></app-header>
    
    <main id="main">
      <transition name="fade-down" mode="out-in" appear>
        <router-view></router-view>
      </transition>
    </main>

    <app-footer></app-footer>

    <modal-cardless-transactions-notice/>
    <modal-link-bank-account-notice/>
    <modal-relink-bank-account-notice/>
    <modal-link-bank-account-monthly-notice/>
  </div>
</template>

<script>
  // Components
  import AppHeader from '@/components/layout/header/AppHeader';
  import AppFooter from '@/components/layout/footer/AppFooter';

  // Alerts
  import AlertGlobal from '@/components/ui/alert/AlertGlobal';

  // Mixins
  import {mixinMetaHandler} from '@/mixins/metaHandler';

  // Modals
  import ModalLinkBankAccountNotice from "@/components/ui/modal/ModalLinkBankAccountNotice";
  import ModalLinkBankAccountMonthlyNotice from "@/components/ui/modal/ModalLinkBankAccountMonthlyNotice";
  import ModalRelinkBankAccountNotice from "@/components/ui/modal/ModalRelinkBankAccountNotice";
  import ModalCardlessTransactionsNotice from '@/components/ui/modal/ModalCardlessTransactionsNotice';

  // Models
  import TranslationsApi from "@/models/translationsApi";
  import ProfileApi from "@/models/profileApi";

  // Configs
  import loginConfig from '@/configs/loginConfig';

  //Accessibility link
  import LinkSkipMainContent from '@/components/ui/links/LinkSkipMainContent';


  export default {
    name: 'App',

    mixins: [mixinMetaHandler],

    components: {
      ModalLinkBankAccountMonthlyNotice, LinkSkipMainContent,
      AppHeader, AppFooter, AlertGlobal, ModalCardlessTransactionsNotice, ModalLinkBankAccountNotice, ModalRelinkBankAccountNotice,
    },
    methods: {
      getTranslations() {
        if (!this.$translations.getTranslationsSessionStorage()) {
          let _this = this;
          new TranslationsApi({}, {}).get(function (response) {
            if (!response.status || response.status !== 200) {

              // This error should stay hardcoded.
              _this.$root.$emit('ls::global-alert::error',
                  'Teksterne på siden kunne ikke indlæses, prøv at opdatere siden.'
              );
              return;
            }

            // For now this will only be in DA, so we only store DA translations
            _this.$translations.setTranslations(response.data.da);
          });
        }
      },
      getUserProfile() {
        if (this.$user.isAuth()) {
          let _this = this;
          new ProfileApi({}, {}).get(function (response) {
            if (!response.status || response.status === 401 || response.status === 403) {
              _this.$root.killAuth();

              return;
            }

            if (response.status !== 200) {
              _this.$root.$emit('ls::global-alert::error', _this.$translations.getTranslation(
                  'profile_could_not_load_general_error_message'
              ));

              return;
            }
            // Set user info
            _this.$user.setName(response.data.first_name);
            _this.$user.setEverLinkedCard(response.data.everLinkedPaymentCard);
            _this.$user.setActiveLinkedPaymentCards(response.data.activeLinkedPaymentCards);
            _this.$user.setCardlessTransactions(response.data.cardlessTransactions);
            _this.$user.setHasResendableCardTransfers(response.data.hasResendableCardTransfers);
            _this.$user.setHasFailedBankTransfersOnActiveBankAccount(response.data.hasFailedBankTransfersOnActiveBankAccount);
            _this.$user.setCanSeeBankAccountsSection(response.data.canSeeBankAccountsSection);
            _this.$user.setActiveBankAccounts(response.data.activeBankAccounts);

            // get user info
            _this.$user.getUser();

            // Trigger monthly bank account notice.
            _this.$root.$emit('ls::show::modal', 'modal-link-bank-account-monthly-notice');

            // Get auto login redirect val
            let autoLoginRedirect = localStorage.getItem(loginConfig.AUTO_LOGIN_WITH_REDIRECT_KEY);
            if ((_this.$entryManager.getEntryPage() === 'ManageCards' && _this.$route.name !== 'ManageCards') ||
                (_this.$user.hasEverLinkedCard() && autoLoginRedirect === 'ManageCards')) {
              // Delete auto login local storage
              localStorage.removeItem(loginConfig.AUTO_LOGIN_WITH_REDIRECT_KEY);

              _this.$router.push({
                name: 'ManageCards'
              });
            }
          });
        }
      },
    },

    mounted() {
      this.getTranslations();
      this.getUserProfile();
    }
  }
</script>

<style lang="scss">
  #app {
    width: 100%;
    float: left;
  }

  main {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
  }

  @keyframes fadeInDown {
    from {
      opacity: 0.05;
      transform: translate3d(0, -60px, 0);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }

  @keyframes fadeOutUp {
    from {
      opacity: 1;
      transform: none;
    }
    to {
      opacity: 0.05;
      transform: translate3d(0, -60px, 0);
    }
  }

  .fade-down-enter-active {
    animation-name: fadeInDown;
    animation-duration: 0.5s;
  }

  .fade-down-leave-active {
    animation-name: fadeOutUp;
    animation-duration: 0.3s;
  }
</style>
