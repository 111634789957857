<template>
  <modal-default refs="modal-show-linking-info">
    <template v-slot:content>
      <h1 class="h3">
        {{ $translations.getTranslation('link_bank_account_info_modal_title') }}
      </h1>

      <p>
        {{ $translations.getTranslation('link_bank_account_info_modal_description') }}
      </p>
    </template>
  </modal-default>
</template>

<script>
  // Modals
  import ModalDefault from '@/components/ui/modal/components/Default';

  // Buttons

  // Should emit event when remove card btn is pressed

  export default {
    name: "ModalShowLinkingInfo",

    props: {
      data: {
        default: null,
        type: Object
      }
    },

    components: {
      ModalDefault
    },

    data: function() {
      return {
        number: '',
        publicId: '',
        isLoading: false,
      }
    },

    methods: {
      close() {
        this.$root.$emit('bv::hide::modal', 'modal-show-linking-info');
      }
    },

    watch: {
      data: {
        deep: true,
        handler (val) {
          this.number   = val.number;
          this.publicId = val.publicId;
        }
      }
    },
  }
</script>

<style scoped lang="scss">

</style>
