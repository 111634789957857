<template>
  <div>
  <div class="cards-manager">

      <div class="manage-cards-sections">
        <div class="manage-cards-section manage-header">
          <h3>
            {{ $translations.getTranslation('cards_manager_table_title1') }}
          </h3>

          <div>
            <a :href="getRoutePath('LinkCard')" @click.prevent="addMoreCardsBtn()" class="link-reverse-standard" role="link">
              <p>
                {{ $translations.getTranslation('cards_manager_table_add_card_button') }}
              </p>

              <span>
                <b-icon icon="plus" aria-hidden="true" role="presentation"></b-icon>
              </span>
            </a>
          </div>
        </div>
      </div>

      <b-table class="cards-table" striped hover :fields="cardsTableFields" :items="wallet.cards" :per-page="wallet.visibleLimit" show-empty>
        <template slot="empty">
          <i v-if="isLoading()">{{ $translations.getTranslation('cards_manager_loading_data_label') }}</i>
          <i v-else>{{ $translations.getTranslation('cards_manager_table_no_cards_message_label') }}</i>
        </template>

        <template v-slot:cell(type)="data">
            <span class="scheme-logo-item">
              <img :src="require(`@/assets/images/schemes/${data.item.type}.png`)" width="32" height="20" alt="Icon" :aria-label="ariaLabelScheme(data.item.type)">
            </span>
        </template>

        <template v-slot:cell(pan_last_4)="data">
          <div class="wrapper">
            <span class="card-number">
              *** {{ data.item.pan_last_4 }}
            </span>
          </div>
        </template>

        <template v-slot:cell(linked_at)="data">
          <div class="wrapper1">
            <span class="linked-at">
              Tilføjet d. {{ data.item.linked_at }}
            </span>
          </div>
        </template>

        <template v-slot:cell(public_id)="data">
          <div class="wrapper">
            <a href="#" target="_self" v-on:click.prevent="removeCardModalTrigger(data)" role="button">
              <p>
                {{ $translations.getTranslation('cards_manager_table_remove_card_button') }}

                <span class="sr-only">
                  <!-- Whitespace necessary to ensure proper spacing for screen readers -->
                  &nbsp;
                  
                  *** {{ data.item.pan_last_4 }}
                </span>
              </p>

              <span class="remove-card-icon">
                <b-icon icon="dash" aria-hidden="true" role="presentation"></b-icon>
              </span>
            </a>
          </div>
        </template>

      </b-table>

    <div class="manage-more" v-if="isViewAllCardsBtnVisible()">
      <a href="#" target="_self" class="text-right" v-on:click.prevent="viewAllCardsOnClick()" role="button">
        {{ $translations.getTranslation('cards_manager_table_view_all_cards_button') }} <b-icon icon="chevron-down" aria-hidden="true" role="presentation"></b-icon>
       </a>
    </div>

    <modal-remove-card :data="removeInfo" @onCardRemove="onCardRemove"/>
    <modal-card-removed/>
    <modal-last-card-removed/>
    <modal-max-cards-reached/>
  </div>
  </div>
</template>

<script>
  // Mixins
  import { mixinRoutesHandler } from '@/mixins/routesHandler';
  import { mixinWindowResizeListener } from '@/mixins/windowResizeListener';

  // Modals
  import ModalRemoveCard from '@/views/manageCards/components/ModalRemoveCard';
  import ModalCardRemoved from '@/views/manageCards/components/ModalCardRemoved';
  import ModalLastCardRemoved from '@/views/manageCards/components/ModalLastCardRemoved';
  import ModalMaxCardsReached from '@/views/manageCards/components/ModalMaxCardsReached';

  // Models
  import CardsApi from "@/models/cardsApi";

  export default {
    name: 'CardsManager',

    mixins: [mixinWindowResizeListener, mixinRoutesHandler],

    components: {
      ModalRemoveCard, ModalCardRemoved, ModalLastCardRemoved, ModalMaxCardsReached
    },

    data: function() {
      return {
        cardsTableFields: [
          {
            key: 'type',
            sortable: false
          },
          {
            key: 'pan_last_4',
            sortable: false
          },
          {
            key: 'linked_at',
            sortable: false
          },
          {
            key: 'public_id',
            sortable: false
          },
        ],

        wallet: {
          total: null,
          visibleLimit: 2,
          cards: []
        },

        removeInfo: {
          number: null,
          publicId: null,
        },

        desktopBreakPoint: 980,

        loading: false,
      }
    },

    methods: {
      ariaLabelScheme(scheme) {
        switch (scheme) {
          case 'visa-vop':
            return 'Visa'
          case 'mc-cls':
            return 'Mastercard'
          case 'nets-storebox':
            return 'Dankort'
          default:
            return 'Icon';
        }
      },
      startLoader() {
        this.loading = true;
      },

      stopLoader() {
        this.loading = false;
      },

      isLoading() {
        return this.loading;
      },

      isMaxNumberOfCardsReached() {
        return this.wallet.cards.length < 15;
      },

      addMoreCardsBtn() {
        if (this.isMaxNumberOfCardsReached()) {
          this.goToRoute('LinkCard');
          return;
        }

        this.$root.$emit('bv::show::modal', 'modal-max-cards-reached');
      },

      goToRoute(routeName) {
        this._mixinGoToRouteName(routeName);
      },

      getRoutePath(routeName) {
        return this._mixinGetRoutePath(routeName);
      },

      removeCardModalTrigger(data) {
        this.removeInfo.number   = data.item.pan_last_4;
        this.removeInfo.publicId = data.item.public_id;

        this.$root.$emit('bv::show::modal', 'modal-remove-card');
      },

      prepareCardsListForDesktopView(windowWith) {
        if (windowWith >= this.desktopBreakPoint && this.wallet.total !== this.wallet.visibleLimit) {
          this.showAllCards();
        }
      },

      isViewAllCardsBtnVisible() {
        return this.wallet.total > this.wallet.visibleLimit;
      },

      showAllCards() {
        this.wallet.visibleLimit = this.wallet.total;
      },

      viewAllCardsOnClick() {
        this.showAllCards();
      },

      getCards() {
        this.startLoader();

        let _this = this;
        new CardsApi({}, {}).get(function (response) {
          _this.stopLoader();

          if (!response.status || response.status === 401 || response.status === 403) {
            _this.$root.killAuth();
            return;
          }

          if (response.status !== 200) {
            // No need for global error, the Cards table will show a message "No transactions to show."
            return;
          }

          _this.wallet.cards = response.data;
          _this.updateCardsLength();
        });
      },

      getTotalCards() {
        return this.wallet.total;
      },

      updateCardsLength() {
        this.wallet.total = this.wallet.cards.length;
      },

      onCardRemove(data) {
        let _this = this;
        new CardsApi({}, {urlExtension: data.publicId}).delete(function (response) {
          if (!response.status || response.status === 401 || response.status === 403) {
            _this.$root.killAuth();
            return;
          }

          if (response.status !== 200) {
            _this.$root.$emit('bv::hide::modal', 'modal-remove-card');

            _this.$root.$emit('ls::global-alert::error', _this.$translations.getTranslation(
                'cards_manager_remove_card_failed_error_message'
            ));

            return;
          }

          _this.$root.$emit('bv::hide::modal', 'modal-remove-card');

          _this.getCards();

          if ((_this.getTotalCards() -1) === 0) {
            _this.$root.$emit('bv::show::modal', 'modal-last-card-removed');
            return;
          }

          _this.$root.$emit('bv::show::modal', 'modal-card-removed');
        });
      }
    },

    mounted() {
      this.getCards();
    },

    watch: {
      'mixinWindowResizeListenerData.width': function (newVal) {
        this.prepareCardsListForDesktopView(newVal);
      },

      'wallet.cards': function () {
        this.prepareCardsListForDesktopView(this.mixinWindowResizeListenerData.width);
      },
    },
  }
</script>

<style scoped lang="scss">
  div {
    ::v-deep .cards-manager {
      width: 100%;

      .manage-more {
        a {
          svg {
            font-size: 15px;
          }
        }
      }

      .manage-cards-sections {
        .manage-cards-section {
          display: flex;
          flex-flow: row;
          padding-bottom: 7.5px;
          padding-top: 7.5px;
          justify-content: space-between;

          &:not(:first-child) {
            border-bottom: 1px solid #eee;
          }

        }

        .manage-header {
          h3, a {
            flex: 1 1 0;
          }

          h3 {
            font-size: 16px;
            color: black;
            margin: 0;
          }

          a {
            color: black;
            display: flex;
            flex-flow: row;
            justify-content: flex-end;
            align-items: center;

            p {
              font-size: 14px;
              font-weight: 600;
              margin: 0;
              line-height: 32px;
              padding-right: 8px;
            }

            span {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 30px;

              background-color: #3ba9dc;
              border-radius: 4px;
              line-height: 27px;
              height: 24px;
              width: 24px;
              color: white;
            }
          }
        }
      }

      .cards-table {
        thead {
          display: none;
        }

        tbody tr {
          background-color: transparent;
          font-size: 12px;

          td {
            padding: 12px 7.5px;
            vertical-align: middle;
          }

          &:nth-child(1) {
            td {
              border-top: none;
            }
          }

          &:last-child {
            border-bottom: 1px solid #dee2e6;
          }

          td:nth-child(1) {
            padding-left: 0;
            width: 50px;

            .scheme-logo-item {
              display: block;

              img {
                height: 100%;
                max-height: 20px;
              }
            }
          }

          td:nth-child(2) {
            white-space: nowrap;
            width: 1px;

            .wrapper {
              display: flex;
              justify-content: space-between;
              flex-direction: column;
            }
          }

          td:nth-child(4) {
            padding-right: 0;

            .wrapper {
              display: flex;
              flex-flow: row;
              justify-content: flex-end;
              flex-basis: 30%;

              a {
                display: flex;
                align-items: center;
                color: black;

                p {
                  display: none;
                  margin: 0;
                  line-height: 30px;
                  padding-right: 8px;
                  font-size: 14px;
                }

                .remove-card-icon {
                  background-color: #bfbcb5;
                  border-radius: 4px;
                  line-height: 27px;
                  height: 24px;
                  width: 24px;
                  color: white;
                  font-size: 30px;

                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }

        }

        @media (min-width: 380px) {
          tbody tr {
            td:nth-child(4) {
              .wrapper {
                a {
                  p {
                    display: block;
                  }
                }
              }
            }
          }
        }

        @media (min-width: 980px) {
          tbody tr {
            font-size: 16px;
          }
        }
      }
    }
  }
</style>
