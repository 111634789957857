<template>
  <div>
    <modal-default refs="modal-transactions-list" :contentSlot="false" :buttonsSlot="false" classes="dialog-max-width-800">

      <transactions-list
              @onPaginationPageChange="onPaginationPageChange"
              :postings="postings"
              :totalRows="totalRows"
              :perPage="perPage"
              :loading="loading"
              :modal="true"/>

    </modal-default>
  </div>
</template>

<script>
  import ModalDefault from '@/components/ui/modal/components/Default';
  import TransactionsList from '@/views/manageCards/components/TransactionsList';

  export default {
    name: "ModalTransactionsList",

    props: {
      postings: Array,
      totalRows: Number,
      perPage: {
        default: 10,
        type: Number,
      },
      loading: {
        default: false,
        type: Boolean
      }
    },

    components: {
      ModalDefault, TransactionsList
    },

    methods: {
      onPaginationPageChange(data) {
        this.$emit('onPaginationPageChange', data)
      }
    }
  }
</script>

<style lang="scss" scoped>
div {
  /deep/

  .h3 {
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
  }

  .modal-dialog {
    max-width: 800px !important;
  }

}
</style>
