<template>
  <div>
    <checkbox-default
            :isVisible="true"
            checkboxId="consent-standard"
            checkboxRef="standard"
             :aria-label="$translations.getTranslation('consent1_label_part1')"
            @onChange="onChange">

      <div class="label-content" slot="label-content">
        {{ $translations.getTranslation('consent1_label_part1') }}

        <a href="#" v-on:click.prevent.stop="termAndConditionsHandler" role="button"
          :aria-label="`${$translations.getTranslation('consent1_label_link1')} ${$translations.getTranslation('aria_label_opens_modal')}`"
        >
          {{ $translations.getTranslation('consent1_label_link1') }}
        </a>

        {{ $translations.getTranslation('consent1_label_part2') }}
      </div>
    </checkbox-default>

    <checkbox-default
            :isVisible="visible.nordea"
            checkboxId="consent-nordea"
            checkboxRef="nordea"
             :aria-label="$translations.getTranslation('link_card_consent_nordea_label_part1')"
            @onChange="onChange">

      <div slot="label-content">
        {{ $translations.getTranslation('link_card_consent_nordea_label_part1') }}

        <ul>
          <li>{{ $translations.getTranslation('link_card_consent_nordea_label_part2') }}</li>
          <li>{{ $translations.getTranslation('link_card_consent_nordea_label_part3') }}</li>
          <li>{{ $translations.getTranslation('link_card_consent_nordea_label_part4') }}</li>
        </ul>

        {{ $translations.getTranslation('link_card_consent_nordea_label_part5') }}
      </div>
    </checkbox-default>

    <checkbox-default
            :isVisible="visible.seb"
            checkboxId="consent-seb"
            checkboxRef="seb"
             :aria-label="$translations.getTranslation('link_card_consent_seb_label_part1')"
            @onChange="onChange">

      <div slot="label-content">
        {{ $translations.getTranslation('link_card_consent_seb_label_part1') }}

        <ul>
          <li>{{ $translations.getTranslation('link_card_consent_seb_label_part2') }}</li>
          <li>{{ $translations.getTranslation('link_card_consent_seb_label_part3') }}</li>
          <li>{{ $translations.getTranslation('link_card_consent_seb_label_part4') }}</li>
        </ul>

        {{ $translations.getTranslation('link_card_consent_seb_label_part4') }}
      </div>
    </checkbox-default>

    <checkbox-default
            :isVisible="true"
            checkboxId="consent-personal"
            checkboxRef="personal"
            :aria-label="$translations.getTranslation('consent2_label')"
            @onChange="onChange">

      <div slot="label-content">
        {{ $translations.getTranslation('consent2_label') }}
      </div>
    </checkbox-default>
  </div>
</template>

<script>
  // Components
  import checkboxDefault from '@/components/ui/checkbox/Default';

  export default {
    name: 'LinkCardConditions',

    components: {
      checkboxDefault
    },

    data: function () {
      return {
        consents: {
          standard: false,
          personal: false,
          nordea: false,
          seb: false,
        },

        visible: {
          nordea: false,
          seb: false,
        }
      }
    },

    methods: {
      onChange(obj) {
        this.consents[obj['id']] = obj['value'];

        this.validate();
      },

      validate() {
        if (this.visible.nordea) {
          this.$emit('onConsent', this.consents.standard && this.consents.personal && this.consents.nordea);
          return;
        }

        if (this.visible.seb) {
          this.$emit('onConsent', this.consents.standard && this.consents.personal && this.consents.seb);
          return;
        }

        this.$emit('onConsent', this.consents.standard && this.consents.personal);
      },

      consentShow(id) {
        this.visible[id] = true;
      },

      consentHide() {
        this.consents.nordea = false;
        this.consents.seb    = false;

        this.visible.nordea = false;
        this.visible.seb    = false;
      },

      termAndConditionsHandler() {
        this.$root.$emit('bv::show::modal', 'modal-no-scheme');
      }
    },

    created: function() {
      this.$root.$on('ls::consent-checkbox::show', (id) => {
        this.consentShow(id);
      });

      this.$root.$on('ls::consent-checkbox::hide', () => {
        this.consentHide();
      });
    },
  }
</script>

<style lang="scss" scoped>
  ul {
    list-style: disc;
    margin: 15px 0 15px 40px;
  }

/*  .label-content {
    a {
      display: inline-block !important;
      float: unset !important;
      color: #007bff !important;
    }
  }*/
</style>
