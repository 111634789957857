<template>
  <b-modal id="modal-lopi-mastercard-notice" hide-footer centered no-close-on-esc no-close-on-backdrop hide-header-close>
    <template v-slot:modal-header="">
      <img src="@/assets/images/fdm/logo.svg" alt="FDM logo" aria-hidden="true">
    </template>
    <p class="pb-3">
      {{ $translations.getTranslation('modal_link_card_lopi_mastercard_general_message') }}
    </p>
    <div class="button-wrapper">
      <button-primary @click.native="hideModal()">
        {{ $translations.getTranslation('modal_link_card_lopi_mastercard_general_button') }}
      </button-primary>
    </div>
  </b-modal>
</template>

<script>
// Buttons
import ButtonPrimary from '@/components/ui/button/Primary';

export default {
  name: "ModalLopiMastercardNotice",
  props: {
    data: {
      default: null,
      type: Object
    }
  },
  components: {
    ButtonPrimary
  },

  created() {
    this.$root.$on('ls::show::modal', (value) => {
      if (value === 'modal-lopi-mastercard-notice') {
          this.showModal();
      }
    });
  },

  methods: {
    showModal() {
      this.$bvModal.show('modal-lopi-mastercard-notice');
    },

    hideModal() {
      this.$bvModal.hide('modal-lopi-mastercard-notice');
    }
  }
}
</script>

<style lang="scss" scoped>
#modal-lopi-mastercard-notice {
  border-radius: 0;
  min-height: 250px;
  padding: 0 !important;

  .modal-header {
    img {
      width: 114px;
      display: block;
      margin: auto;
    }
  }

  .button-wrapper {
    width: 100%;
    max-width: 300px;
    margin: auto;

    button {
      margin-bottom: 16px;
    }
  }
}
</style>