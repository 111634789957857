<template>
  <modal-default refs="modal-bank-account-removed">
    <template v-slot:content>
      <h1 class="h3">
        {{ $translations.getTranslation('bank_account_removed_modal_title1') }}
      </h1>

      <p>
        {{ $translations.getTranslation('bank_account_removed_modal_label1') }}
      </p>
    </template>

    <template v-slot:buttons>
      <button-primary @click.native="addAnotherBankAccount">
        {{ $translations.getTranslation('bank_account_removed_modal_button1') }}
      </button-primary>

      <button-secondary @click.native="close">
        {{ $translations.getTranslation('bank_account_removed_modal_button2') }}
      </button-secondary>
    </template>
  </modal-default>
</template>

<script>
  // Modals
  import ModalDefault from '@/components/ui/modal/components/Default';

  // Buttons
  import ButtonPrimary from '@/components/ui/button/Primary';
  import ButtonSecondary from '@/components/ui/button/Secondary';

  export default {
    name: "ModalBankAccountRemoved",

    props: {
      data: {
        default: null,
        type: Object
      }
    },

    components: {
      ModalDefault, ButtonPrimary, ButtonSecondary
    },

    methods: {
      addAnotherBankAccount() {
        this.$root.$emit('bv::hide::modal', 'modal-bank-account-removed');
        this.$emit('onAddAnotherBankAccount', true);
      },

      close() {
        this.$root.$emit('bv::hide::modal', 'modal-bank-account-removed');
      }
    }
  }
</script>

<style scoped lang="scss">
  .button-wrapper {
    width: 100%;
    max-width: 300px;
    margin: auto;

    button {
      margin-bottom: 16px;
    }
  }
</style>
