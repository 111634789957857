<template>
  <transition name="fade">
    <div
      class="input-tooltip"
      :id="id"
      v-if="errorMessage"
      role="alert"
      aria-atomic="true"
      aria-live="assertive"
      aria-relevant="additions"
    >
      <div class="input-tooltip-inner tooltip-error">
        {{ errorMessage }}
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: "InputTooltip",

    props: {
      'errorMessage': {
        default: '',
        type: String
      },
      id: {
        type: String,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.7s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .input-tooltip {
    max-width: 260px;
    left: 16px;
    position: absolute;
    right: 0;
    bottom: 70px;

    .input-tooltip-inner {
      padding: 10px;
      font-size: 13px;

      &.tooltip-error {
        background: #e81e14;
        color: #fff;
      }

      // Arrow
      &:after {
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        border-style: solid;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #e81e14;
        border-width: 16px;
        bottom: -16px;
        left: 0;
        transform: rotate(90deg);
      }
    }
  }
</style>
