<template>
  <a 
    :href="href" 
    :aria-label="ariaLabel" 
    :itemprop="itemProp" 
    :target="target" 
    :class="classes" 
    rel="noopener" 
    :aria-describedby="ariaDescribedByLabel"
  >
    <slot>
      {{ label }} 
    </slot>
    <b-icon icon="box-arrow-up-right" v-if="externalLink" aria-hidden="true" role="presentation"></b-icon>
  </a>
</template>

<script>
  export default {
    name: 'LinkItem',

    props: {
      href: {
        type: String,
        default: '#'
      },
      ariaLabel: {
        type: String,
        default: ''
      },
      itemProp: {
        type: String,
        default: ''
      },
      target: {
        type: String,
        default: '_blank'
      },
      label: {
        type: String,
        default: ''
      },
      classes: {
        type: String,
        default: ''
      },
      externalLink: {
        default: false,
        type: Boolean
      },
      externalLinkText: {
        default: false,
        type: Boolean
      },
    },

    data: function () {
      return {
        ariaDescribedByLabel: '',
      }
    },

    methods: {
      setAriaDescribedBy() {
        if (this.externalLink || this.externalLinkText) {
          this.ariaDescribedByLabel = 'accessibility-external-link-disclaimer';
          return;
        }

        this.ariaDescribedByLabel = '';
      }
    },

    created() {
      this.setAriaDescribedBy();
    },
  }
</script>

<style scoped lang="scss">
  a, a:hover {
    color: #f2f2f2;

    svg {
      margin-left: 5px;
      font-size: 15px;
      vertical-align: -0.05em !important;
    }
  }

  .icon {
    background-size: contain;
    background-repeat: no-repeat;
  }

  .icon--text {
    padding-left: 2rem;
    background-position: 0;
    width: auto;
    height: auto;
    background-size: 1.25rem 1.25rem;
  }

  .icon--twitter-white {
    background-image: url(https://styleguide.fdm.dk/images/icons/footer/twitter.svg);
  }

  .icon--facebook-white {
    background-image: url(https://styleguide.fdm.dk/images/icons/footer/facebook.svg);
  }

  .icon--instagram-white {
    background-image: url(https://styleguide.fdm.dk/images/icons/footer/instagram.svg);
  }

  .icon--linkedin-white {
    background-image: url(https://styleguide.fdm.dk/images/icons/footer/linkedin.svg);
  }

  .icon--rss-white {
    background-image: url(https://styleguide.fdm.dk/images/icons/footer/rss.svg);
  }

  .icon--phone-white {
    background-image: url(https://styleguide.fdm.dk/images/icons/footer/telephone.svg);
  }

  .icon--email-white {
    background-image: url(https://styleguide.fdm.dk/images/icons/footer/mail.svg);
  }
</style>
