<template>
  <b-modal id="modal-link-bank-account-notice" hide-footer centered no-close-on-esc no-close-on-backdrop hide-header-close>
    <template v-slot:modal-header="">
      <img src="@/assets/images/fdm/logo.svg" alt="FDM logo" aria-hidden="true">
    </template>
    <p>
      {{ $translations.getTranslation('modal_link_bank_account_notice') }}
    </p>
    <br><br>
    <div class="button-wrapper">
      <button-primary @click.native="linkBankAccount()">
        {{ $translations.getTranslation('modal_link_bank_account_ok') }}
      </button-primary>

      <button-secondary @click.native="hideModal()">
        {{ $translations.getTranslation('modal_link_bank_account_cancel') }}
      </button-secondary>
    </div>
  </b-modal>
</template>

<script>
// Buttons
import ButtonPrimary from '@/components/ui/button/Primary';
import ButtonSecondary from '@/components/ui/button/Secondary';

export default {
  name: "ModalLinkBankAccountNotice",
  props: {
    data: {
      default: null,
      type: Object
    }
  },
  components: {
    ButtonPrimary, ButtonSecondary
  },

  created() {
    this.$root.$on('ls::show::modal', (value) => {
      if (value === 'modal-link-bank-account-notice') {
        if (this.$user.hasResendableCardTransfers()) {
          this.showModal();
        }
      }
    });
  },

  methods: {
    linkBankAccount() {
      this.$router.push({
        name: 'ManageCards'
      }).then(() => {
        this.hideModal()
      });

    },


    showModal() {
      this.$bvModal.show('modal-link-bank-account-notice');
    },

    hideModal() {
      this.$bvModal.hide('modal-link-bank-account-notice');
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/
#modal-link-bank-account-notice {
  border-radius: 0;
  min-height: 250px;
  padding: 0 !important;

  .modal-header {
    img {
      width: 114px;
      display: block;
      margin: auto;
    }
  }

  .button-wrapper {
    width: 100%;
    max-width: 300px;
    margin: auto;

    button {
      margin-bottom: 16px;
    }
  }

  table {
    font-size: 14px;
    margin-bottom: 0;
    table-layout: fixed;

    thead {
      display: none;
    }

    tbody tr td {
      padding: 2px;
      border: none;
    }

    tbody {
      border-bottom: 1px solid grey;
    }

    tbody tr {
      td {
        padding-top: 10px;

        &:first-child {
          width: 100px;
        }
      }

      &:last-child {
        td {
          padding-bottom: 10px;
        }
      }
    }
  }
}
</style>