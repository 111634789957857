<template>
  <transition name="bounce">
    <div class="global-alerts-component" v-if="visible" role="alert" aria-live="assertive">
      <div class="alert-wrapper alert-error" :class="typeClass">
        <div class="alert-inner">
          <p>{{ label }}</p>

          <a href="#" class="alert-close" :aria-label="$translations.getTranslation('aria_label_close_alert')" :title="$translations.getTranslation('aria_label_close_alert')" role="button"
             @click.prevent="close()">

            <b-icon icon="x" aria-hidden="true" role="presentation"></b-icon>
          </a>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: "AlertDefaultTemplate",

    props: {
      label: {
        default: null,
        type: String
      },
      type: {
        default: 'success',
        type: String
      }
    },

    data: function() {
      return {
        visible: false,
        typeClass: null,
      }
    },

    methods: {
      close() {
        this.visible = false;
        this.$emit('onClose', true);
      },

      typeHandler() {
        if (this.type === 'success') {
          this.typeClass = 'alert-success';
          return;
        }

        if (this.type === 'error') {
          this.typeClass = 'alert-error';
          return;
        }

        this.typeClass = null;
      }
    },

    watch: {
      'label': function (newVal) {
        if (newVal) {
          this.visible = true;
          return;
        }

        this.visible = false;
      },
      'type': function () {
        this.typeHandler();
      },
    }
  }
</script>

<style scoped lang="scss">
  .bounce-enter-active {
    animation: bounce-in .5s;
  }

  .bounce-leave-active {
    animation: bounce-in .5s reverse;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }

  .global-alerts-component {
    .alert-wrapper {
      width: 100%;

      p {
        font-size: 16px;
        margin-bottom: 0;
      }

      .alert-inner {
        position: relative;
        width: 100%;
        max-width: $appWidth;
        margin: auto;
        text-align: center;
        padding: 10px 40px;
        font-size: 13px;
      }

      &.alert-error {
        background: #e81e14;
        color: #fff;
      }

      &.alert-success {
        background: #30a030;
        color: #fff;
      }

      &.alert-info {
        background-color: #fff;
        border: 1px solid #e81e14;
      }

      .alert-close {
        position: absolute;
        padding: 5px 15px;
        top: 2px;
        right: 0;
        color: black;
        cursor: pointer;
        font-size: 30px;
        line-height: 30px;
      }
    }
  }

</style>
