<template>
  <div class="footer-top-wrapper" aria-label="Footer top">
    <div class="footer-top-inner">
      <div class="item">
        <h3 class="call-icon">{{ $translations.getTranslation('footer_top_group1_title1') }}</h3>

        <link-item class="phone-link" :aria-label="`${$translations.getTranslation('accessibility_number_to_call_pre_text')} ${$translations.getTranslation('footer_top_group1_link_label1')}`" href="tel:+4570133040" :label="$translations.getTranslation('footer_top_group1_link_label1')"/>

        <p>{{ $translations.getTranslation('footer_top_group1_text1_part1') }} <br>{{ $translations.getTranslation('footer_top_group1_text1_part2') }}</p>
      </div>

      <div class="item">
        <h3 class="write-icon">{{ $translations.getTranslation('footer_top_group2_title1') }}</h3>

        <p>{{ $translations.getTranslation('footer_top_group2_text1') }}</p>

        <link-item class="write-link" href="https://fdm.dk/om-fdm/kontakt" target="_blank" rel="noopener"
           aria-describedby="accessibility-external-link-disclaimer" :label="$translations.getTranslation('footer_top_group2_link_label1')" external-link />

      </div>

      <div class="item">
        <h3 class="call-icon">{{ $translations.getTranslation('footer_top_group3_title1') }}</h3>

        <link-item href="tel:+4533916688" class="phone-link" :aria-label="`${$translations.getTranslation('accessibility_number_to_call_pre_text')} ${$translations.getTranslation('footer_top_group3_link_label1')}`" item-prop="phone-number" :label="$translations.getTranslation('footer_top_group3_link_label1')" />

        <p>
          {{ $translations.getTranslation('footer_top_group3_text1_part1') }}<br>
          {{ $translations.getTranslation('footer_top_group3_text1_part2') }}<br>
          {{ $translations.getTranslation('footer_top_group3_text1_part3') }}<br>
          {{ $translations.getTranslation('footer_top_group3_text1_part4') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import LinkItem from '@/components/layout/footer/components/LinkItem';

  export default {
    name: 'FooterTop',
    components: {
      LinkItem,
    },
  }
</script>

<style scoped lang="scss">
  .footer-top-wrapper {
    width: 100%;
    float: left;
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto 0;
    padding-top: 2rem;

    background-color: #f2f2f2;
    color: #222;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .footer-top-inner {
      width: 100%;
      max-width: $appWidth;
      margin: auto;
      padding-left: .5em;
      padding-right: .5em;

      background-image: url(https://styleguide.fdm.dk/images/jette-no-background.png);
      background-repeat: no-repeat;
      background-size: 14rem auto;
      background-position: left -4.5rem bottom -12rem;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      .item {
        margin: 0 1rem;
        padding: 1.5rem 0 1.5rem 4.25rem;
        width: 17rem;

        &:last-child {
          padding-bottom: 3rem;
        }

        h3 {
          font-size: 1.25rem;
          position: relative;
          margin: 0;
          text-transform: uppercase;
          line-height: 1.4;
          font-family: "mada-heavy", Helvetice Neue, arial, sans-serif;

          &:before {
            position: absolute;
            top: -.5rem;
            left: -3em;
            width: 3rem;
            height: 3rem;
          }

          &.call-icon:before {
            content: url(https://styleguide.fdm.dk/svg/phone.svg);
          }

          &.write-icon:before {
            content: url(https://styleguide.fdm.dk/svg/mail.svg);
          }
        }

        a {
          color: #3ba9dc;

          &.phone-link {
            text-decoration: none;
            font-size: 2rem;
            font-family: merriweather-heavy,TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif;
          }

          &:hover {
            text-decoration: underline;
          }
        }

        p {
          margin-top: .5rem;
        }

        .write-link, p {
          font-size: 16px;
          font-family: "merriweather-regular", TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
        }
      }
    }

    @media (min-width: 768px) {
      .footer-top-inner {
        background-position: right 3rem bottom -4rem;
        flex-direction: row;
        align-items: flex-start;
        min-height: 494px;

        .item {
          &:last-child {
            width: 36rem;
          }
        }
      }
    }

    @media (min-width: 1124px) {
      .footer-top-inner {
        padding-left: 18.5rem;
        background-size: 18rem auto;
        background-position: left 6rem bottom -14rem;
        flex-wrap: nowrap;
        min-height: 256px;

        .item {
          &:last-child {
            width: 17rem;
          }
        }
      }
    }
  }
</style>
