<template>
  <modal-default refs="modal-remove-bank-account" :loading="isLoading">
    <template v-slot:content>
      <h1 class="h3">
        {{ $translations.getTranslation('remove_bank_account_modal_title1') }} {{ number }}
      </h1>

      <p>
        {{ $translations.getTranslation('remove_bank_account_modal_label1') }}
      </p>
    </template>

    <template v-slot:buttons>
      <button-primary @click-state="removeBankAccount" :spinner="isLoading">
        {{ $translations.getTranslation('remove_bank_account_modal_button1') }}
      </button-primary>

      <button-secondary @click-state="close" :aria-disabled="isLoading">
        {{ $translations.getTranslation('remove_bank_account_modal_button2') }}
      </button-secondary>
    </template>
  </modal-default>
</template>

<script>
  // Modals
  import ModalDefault from '@/components/ui/modal/components/Default';

  // Buttons
  import ButtonPrimary from '@/components/ui/button/Primary';
  import ButtonSecondary from '@/components/ui/button/Secondary';

  // Should emit event when remove card btn is pressed

  export default {
    name: "ModalRemoveBankAccount",

    props: {
      data: {
        default: null,
        type: Object
      }
    },

    components: {
      ModalDefault, ButtonPrimary, ButtonSecondary
    },

    data: function() {
      return {
        number: '',
        publicId: '',
        isLoading: false,
      }
    },

    methods: {
      startLoader() {
        this.isLoading = true;
      },

      stopLoader() {
        this.isLoading = false;
      },

      removeBankAccount() {
        this.startLoader();

        this.$emit('onBankAccountRemove', {
          publicId: this.publicId,
        });
      },

      close() {
        this.$root.$emit('bv::hide::modal', 'modal-remove-bank-account');
      }
    },

    mounted() {
      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        if (modalId === 'modal-remove-bank-account') {
          this.stopLoader();
        }
      })
    },

    watch: {
      data: {
        deep: true,
        handler (val) {
          this.number   = val.number;
          this.publicId = val.publicId;
        }
      }
    },
  }
</script>

<style scoped lang="scss">

</style>
