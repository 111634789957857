<template>
  <div class="input-floating-label-component">
    <div class="input-disclaimer" v-if="requiredDisclaimer">
      <p>
        {{ $translations.getTranslation('input_floating_label_required_disclaimer_label') }}
      </p>
    </div>

    <div class="input-wrapper">
      <label :for="id" aria-hidden="true" :class="{ 'is-floating': label.isFloating}">
        {{ labelName }}
      </label>

      <input v-model="input.value"
             v-mask="mask"
             :class="[isInputFilled, isAriaDisabledInput]"
             :type="input.type"
             :id="id"
             :name="id"
             :ref="refId"
             :autocomplete="autocomplete"
             :maxlength="maxLength"
             :disabled="disabled"
             :aria-disabled="isAriaDisabled"
             :inputmode="inputmode"
             :readonly="isReadonly"
             :aria-readonly="isReadonly"
             :placeholder="isLabelFloating() ? placeholder : null"
             :aria-label="ariaLabelInput"
             @keyup.tab="onTab()"
             @keyup.enter="onEnter()"
             @click="onClick()"
             @click.right="onClick()"
             @blur="onBlur()"
             :aria-invalid="isInvalid"
             :aria-errormessage="`input-error-${id}`"
      >


      <slot name="image"> </slot>

      <a href="#" v-if="type === 'password' && passwordShowBtn" @click.prevent="togglePassword">
        <span v-if="!showPassword">{{ $translations.getTranslation('input_floating_label_password_toggle_button_show') }}</span>
        <span v-if="showPassword">{{ $translations.getTranslation('input_floating_label_password_toggle_button_hide') }}</span>
      </a>
    </div>

    <input-tooltip :errorMessage="errorMessage" :id="`input-error-${id}`" />
  </div>
</template>


<script>
  // Components
  import InputTooltip from '@/components/ui/tooltip/InputTooltip';

  export default {
    name: "InputFloatingLabel",

    props: {
      'id': {
        default: '',
        type: String
      },
      'refId': {
        default: '',
        type: String
      },
      'type': {
        default: 'text',
        type: String
      },
      'autocomplete': {
        default: 'off',
        type: String
      },
      'requiredDisclaimer': {
        default: false,
        type: Boolean
      },
      'maxLength': {
        default: '',
        type: String
      },
      'mask': {
        default: '',
        type: String
      },
      'labelName': {
        default: '',
        type: String
      },
      'errorMessage': {
        default: '',
        type: String
      },
      'disabled': {
        default: false,
        type: Boolean
      },
      'ariaDisabled': {
        default: false,
        type: Boolean
      },
      'readonly': {
        default: false,
        type: Boolean
      },
      'value': {
        default: '',
        type: String
      },
      'ariaLabelText': {
        default: '',
        type: String
      },
      'passwordShowBtn': {
        default: false,
        type: Boolean
      },
      'placeholder': {
        default: null,
        type: String
      },
      'inputmode': {
        type: String,
        default: 'text',
        validator: (value) => {
          return (
            [
              'text',
              'decimal',
              'numeric',
              'tel',
              'search',
              'email',
              'url',
            ].indexOf(value) !== -1
          );
        },
      },
    },

    components: {
      InputTooltip,
    },

    data: function() {
      return {
        label: {
          isFloating: false,
        },

        input: {
          value: null,
          type: this.type
        },

        showPassword: false,
      }
    },

    computed: {
      isDisabled() {
        return this.disabled;
      },
      isAriaDisabled() {
        return this.ariaDisabled || this.disabled;
      },
      isReadonly() {
        return this.readonly || this.ariaDisabled;
      },
      isAriaDisabledInput() {
        return this.ariaDisabled ? 'aria-disabled-input' : null;
      },
      isInputFilled() {
        return this.value ? 'input-filled' : '';
      },
      ariaLabelInput() {
        return this.ariaLabelText ? this.ariaLabelText : this.labelName
      },
      isInvalid() {
        return this.errorMessage ? true : false;
      }
    },

    methods: {
      isLabelFloating() {
        return this.label.isFloating;
      },

      togglePassword() {
        if (this.type === 'password' && this.passwordShowBtn) {
          this.showPassword = !this.showPassword;

          if (this.showPassword) {
            this.input.type = 'text';
          } else {
            this.input.type = 'password';
          }
        }
      },

      getRefId() {
        return this.refId;
      },

      onTab() {
        this.toggleFloatingLabel(true);
      },

      onEnter() {
        this.$refs[this.getRefId()].blur();

        this.$emit('OnEnter');
      },

      onClick() {
        this.toggleFloatingLabel(true);
      },

      onBlur() {
        this.toggleFloatingLabel(!!this.input.value);
      },

      toggleFloatingLabel(state) {
        this.label.isFloating = state;
      },

      hasFixedValue() {
        this.input.value = this.value;

        if (this.value) {
          this.toggleFloatingLabel(true);
        } else {
          this.toggleFloatingLabel(false);
        }
      },

      resetInput() {
        this.input.value = null;
        this.toggleFloatingLabel(false);
      }
    },

    mounted: function () {
      this.$nextTick(function () {
        this.hasFixedValue();
      });
    },

    created: function() {
      this.$root.$on('ls::input-floating-label::reset-value', (id) => {
        if (id === this.id) {
          this.resetInput();
        }
      });
    },

    watch: {
      'input.value': function(newVal) {
        this.$emit('onInput', {
          refId: this.getRefId(),
          value: newVal,
        });
      },

      'errorMessage': function(newVal) {
        if (newVal) {
          this.$refs[this.refId].focus();
          this.$refs[this.refId].click();
        }
      },

      'value': function() {
        this.hasFixedValue();
      },
    }
  }
</script>


<style lang="scss" scoped>
  .input-floating-label-component {
    width: 100%;
    position: relative;

    .input-disclaimer {
      width: 100%;

      p {
        font-size: 12px;
        margin: 0;
        text-align: right;
      }
    }

    .input-wrapper {
      width: 100%;
      height: 64px;
      position: relative;

      input {
        font-size: 16px;
        border-radius: 4px;
        border: 1px solid #bfbcb5;
        padding: 20px 10px 0 16px;
        height: 64px;
        width: 100%;
        cursor: text;
        font-family: "merriweather-regular", TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;

        &:focus, &:hover {
          background-color: #f4f4f4;
        }
        
        &.aria-disabled-input,
        &:read-only,
        &:disabled {
          background-color: #ebebe4;
          color: light-dark(rgb(84, 84, 84), rgb(170, 170, 170));

          &:focus-visible, &:hover {
            background-color: #ebebe4;
          }

          &:focus-visible {
            outline: none;
          }
        }

        &[type="number"] {
          -moz-appearance: textfield;
        }

        &[type="number"]::-webkit-outer-spin-button, &[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &[type="file"] {
          display: none;
        }
      }

      a {
        position: absolute;
        top: 24px;
        font-size: 16px;
        right: 10px;
        font-family: "merriweather-regular", TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      label {
        position: absolute;
        top: 22px;
        font-size: 16px;
        padding-left: 16px;
        left: 0;
        transition: all 0.1s;
        touch-action: manipulation;
        font-family: "merriweather-regular", TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;

        &.is-floating {
          font-weight: 600;
          font-size: 13px;
          top: 5px;
        }
      }

      // Remove x from input in edge
      input::-ms-clear {
        display: none;
      }

      img {
        position: absolute;
        top: 22px;
        right: 10px;
        max-height: 21px;
      }
    }

    .message {
      width: 100%;
      margin-block: 0.25rem 0;
      font-size: 0.875em;
    }
  }
</style>
