<template>
  <button
    :class="[classes, isAriaDisabledBtn, isLoading]"
    :aria-disabled="isAriaDisabled"
    @click="clickState"
  >
    <span :aria-hidden="spinner">
      <slot />
    </span>

    <b-spinner
      class="spinner"
      v-if="spinner"
      role="loader"
      aria-hidden="true"
    />
    <span class="sr-only" v-if="spinner" role="alert" aria-live="assertive">
      <slot />

      {{ $translations.getTranslation('aria_label_icon_loading_spinner') }}
    </span>
  </button>
</template>

<script>
  export default {
    name: "ButtonDefaultTemplate",

    props: {
        classes: {
            default: '',
            type: String
        },
        ariaDisabled: {
            default: false,
            type: Boolean
        },
        disabled: {
            default: false,
            type: Boolean
        },
        spinner: {
          default: false,
          type: Boolean
        },
    },

    methods: {
      clickState() {
        if (this.ariaDisabled || this.spinner) {
          return;
        }

        this.$emit('click-state');
      }
    },
      computed: {
        isAriaDisabledBtn() {
          return this.ariaDisabled ? 'aria-disabled-btn' : null;
        },
        isDisabled() {
          return this.disabled || this.spinner;
        },
        isAriaDisabled() {
          return this.ariaDisabled || this.disabled || this.spinner;
        },
        isLoading() {
          return this.spinner ? 'loading' : null;
        },
      }
  }
</script>

<style lang="scss" scoped>
    button {
      width: 100%;
      padding: .6rem 1rem;
      margin: 0 .2em 0 0;
      border: 2px solid;
      border-radius: 2px;
      font-family: merriweather-heavy,TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif;
      font-size: 1rem;
      line-height: 1.5;
      text-align: center;
      transition: none;
      box-shadow: none;
      cursor: pointer;

      &.button-primary {
          border-color: #f9bf3b;
          background-color: #f9bf3b;
          color: #222;

          &:hover {
              border-color: #f7af0a;
              background-color: #f7af0a;
          }
      }

      &.button-secondary {
        border-color: #3ba9dc;
        background-color: white;
        color: #222;

        &:hover {
          color: white;
          border-color: #3ba9dc;
          background-color: #3ba9dc;
        }
      }

      &.loading {
        cursor: progress;
      }

      .spinner {
        width: 15px;
        height: 15px;
        margin-left: 5px;
        border-width: 2px;
        margin-bottom: 2px;
      }

      &.aria-disabled-btn,
      &:disabled,
      &.loading {
          border-color: #e2e2e2;
          background-color: #e2e2e2;
          color: #606060;
          cursor: not-allowed;

          &:hover {
              border-color: #e2e2e2;
              background-color: #e2e2e2;
              color: #606060;
          }
          
      }
    }
</style>
