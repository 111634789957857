<template>
  <a
    href="#main"
    class="skip-links link-button-primary visually-hidden-focusable"
  >
    {{ $translations.getTranslation('aria_label_skip_links_text' , 'Skip to main content')  }} 
  </a>
</template>

<script>
export default {
  name: 'SkipLinks'
}
</script>

<style lang="scss" scoped>
.skip-links {
  display: none;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

@media screen and (hover: hover) and (pointer: fine) {
  .skip-links {
    position: absolute;
    top: 3px;
    left: 3px;
    z-index: 1030;
    display: none;
  }

  .link-button-primary {
    border-color: #f9bf3b;
    background-color: #f9bf3b;
    color: #222;

    min-height: 3.375rem;
    padding-inline: 32px;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 700;

    width: 100%;
    text-decoration: none;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    text-align: left;

    min-width: 216px;
    width: max-content;

    &:hover,
    &:focus,
    &:active {
      border-color: #f7af0a;
      background-color: #f7af0a;
      text-decoration: unset;
    }
  }

  html[dir='rtl'] {
    .skip-links {
      right: 2px;
    }
  }
}
</style>